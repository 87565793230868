import React from "react"
import Helmet from "react-helmet"

import ContactHero from "../components/Contact/ContactHero"
import PageTemplate from "../templates/PageTemplate"

const Contact = () => {
  return (
    <PageTemplate>
      <Helmet title="Moja oaza - Kontakt" />
      <ContactHero />
    </PageTemplate>
  )
}

export default Contact
