import React from "react"

import FormWrapper from "./FormWrapper"
import Map from "../Map"

const ContactHero = () => {
  return (
    <section className="bg-gray-300">
      <div className="container pt-24">
        <FormWrapper />
      </div>
      <Map />
    </section>
  )
}

export default ContactHero
