import React, { useState } from "react"
import { useForm } from "@formspree/react"
import styled from "styled-components"
import { GoogleReCaptcha } from "react-google-recaptcha-v3"

const Checkbox = styled.input`
  visibility: hidden;
  position: absolute;
  &:checked + label {
    &:before {
      transform: translateX(22px);
    }
  }
`

const Toggle = styled.label`
  display: flex;
  width: 50px;
  height: 27px;
  border: 2px solid;
  border-radius: 99em;
  position: relative;
  transition: transform 0.5s ease-in-out;
  transform-origin: 50% 50%;
  cursor: pointer;

  &:before {
    transition: transform 0.5s ease;
    content: "";
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #000;
    border-radius: 50%;
    top: 4px;
    left: 5px;
  }
`

const Form = () => {
  const [token, setToken] = useState()

  const [state, handleSubmit] = useForm("mnqwpjvb", {
    data: { "g-recaptcha-response": token },
  })

  if (state.succeeded) return <p>Wysłano wiadomość</p>

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="sr-only" htmlFor="name">
          Imię
        </label>
        <input
          className="w-full p-3 text-sm border border-gray-200 rounded-lg"
          placeholder="Imię"
          type="text"
          id="name"
          name="name"
          required
        />
      </div>
      <div>
        <label className="sr-only" htmlFor="email">
          Email
        </label>
        <input
          className="w-full p-3 text-sm border border-gray-200 rounded-lg"
          placeholder="Email"
          type="email"
          id="email"
          name="email"
          required
        />
      </div>

      <div>
        <label className="sr-only" htmlFor="message">
          Wiadomość
        </label>
        <textarea
          className="w-full p-3 text-sm border border-gray-200 rounded-lg"
          placeholder="Wiadomość"
          rows="8"
          id="message"
          name="message"
          required
        ></textarea>
      </div>

      <div className="flex gap-3 items-center">
        <p>Pastor</p>
        <div>
          <Checkbox
            type="checkbox"
            id="toggle"
            name="for"
            value="administrator"
          />
          <Toggle htmlFor="toggle" className="border-gray-200"></Toggle>
        </div>
        <p>Administrator</p>
      </div>
      <GoogleReCaptcha
        onVerify={token => {
          setToken(token)
        }}
      />
      <div className="mt-4 flex justify-end">
        <button
          disabled={state.submitting}
          className="inline-flex items-center justify-center w-full px-5 py-3 text-white bg-black rounded-lg sm:w-auto"
        >
          <span className="font-medium text-white">Wyślij</span>
        </button>
      </div>
    </form>
  )
}

export default Form
