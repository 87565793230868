import React from "react"

const Map = () => {
  return (
    <div>
      <iframe
        title="map"
        width="100%"
        height="300"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        src="https://maps.google.com/maps?q=Ko%C5%9Bci%C3%B3%C5%82%20Chrze%C5%9Bcijan%20Baptyst%C3%B3w%20OAZA&t=&z=17&ie=UTF8&iwloc=&output=embed"
      ></iframe>
    </div>
  )
}

export default Map
