import React from "react"
import Form from "./Form"

const FormWrapper = () => {
  return (
    <section>
      <div className="max-w-screen-xl lg:px-4 py-8 lg:py-16 mx-auto sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
          <div
            className="lg:py-12 lg:col-span-2"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <h1 className="max-w-xl font-bold text-xl">
              Kościół Chrześcijan Baptystów OAZA
            </h1>
            <div className="mt-8">
              <p>
                Telefon (Administracja):{" "}
                <a href="tel:+48724151549" className="font-bold">
                  +48 724 151 549
                </a>
              </p>

              <address className="mt-2 not-italic">
                Dziechcinka 3A, 43-460 Wisła
              </address>

              <p className="mt-2">
                Konto bankowe: 30 1050 1096 1000 0001 0592 3221
              </p>
              <p className="mt-2">
                Code SWIFT: INGBPLPW 30 1050 1096 1000 0001 0592 3221
              </p>
            </div>
          </div>

          <div
            className="p-8 bg-white rounded-lg shadow-lg lg:p-12 lg:col-span-3"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <Form />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FormWrapper
